<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="
          $router.push({
            name: 'vacancy'
          })
        "
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>
      <a-row class="post-search" type="flex">
        <a-select
          v-model="perPage"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="all-news-btn"
          style="margin: 0 15px"
          @click="Allnews"
        >
          Все
        </a-button>
        <a-button type="primary" @click="createFunc()">
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>
      <a-row type="flex" style="width: 100%; justify-content: space-between">
        <h1>{{ title[$i18n.locale] }}</h1>
        <a-button
          :disabled="cardsIsEmpty"
          type="danger"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          <!--{{ $t("Delete") }}-->
        </a-button>
      </a-row>
    </a-row>
    <a-table
      style="overflow-x: auto"
      :columns="columns"
      :data-source="categoryProps.results || []"
      :loading="loading"
      :pagination="false"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'vacDepart-update',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="department_title" slot-scope="item">
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'vacDepart-update',
              params: { id: item.id }
            })
          "
        >
          <b>{{ item.title || "-" }}</b>
        </span>
        <!-- <a-tag v-if="item.title">
          <img
            width="14"
            class="flag-svg__tab"
            :src="contentLanguages[getFirstFulfilled(item.title).lang].flagSrc || ''"
            :alt="getFirstFulfilled(item.title).lang"
          />
          {{ contentLanguages[getFirstFulfilled(item.title).lang].abr }}
        </a-tag> -->
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="action-btns"
          @click="
            $router.push({
              name: 'vacDepart-update',
              params: { id: item.id }
            })
          "
        >
          <a-icon class="action-btns" type="edit" />
        </a-button>
      </template>
    </a-table>
    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+categoryProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          title: "ID",
          key: "id",
          width: 100,
          // fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TableHead"),
          width: 500,
          key: "department_title",
          // fixed: "left"
          scopedSlots: { customRender: "department_title" }
        },
        {
          title: "Slug",
          key: "slug",
          width: 300,
          // fixed: "left",
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: 220,
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: 220,
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          // width: "80%",
          fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      title: {
        oz: "Вакансия учун департаментлар",
        uz: "Vakansiya uchun departamentlar",
        qr: "Департаменты для вакансии",
        ru: "Департаменты для вакансии",
        en: "Departments for vacancy"
      },
      categoryProps: {
        results: [],
        count: 0
      },
      LimitList: [
        {
          value: 10,
          title: "10"
        },
        {
          value: 20,
          title: "20"
        },
        {
          value: 40,
          title: "40"
        }
      ],
      cardsIsEmpty: true,
      perPage: 20,
      page: 1,
      offset: 0
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
      console.log(this.selectedPosts)
    },
    tableperPage() {
      this.offset = 0
      this.fetchData()
    },
    async Allnews() {
      this.loading = true
      this.perPage = null
      this.categoryProps = {
        results: [],
        count: 0
      }
      try {
        const r = await this.$api.get("/admin/vacancy/departament/list/")
        if (r) {
          this.$set(this, "categoryProps", {
            results: (r && r.data) || []
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    async fetchData() {
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const r = await this.$api.get("/admin/vacancy/departament/list/", {
          params: {
            limit: this.perPage,
            offset: this.offset
          }
        })
        if (r) {
          this.$set(this, "categoryProps", {
            results: (r.data && r.data.results) || [],
            count: (r.data && r.data.count) || 0
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$api.delete(
              "/admin/vacancy/departament/" + i + "/delete/"
            )
            if (res && res.status === 204) {
              this.$message.success("Новости Успешно удалено")
            }
          } catch (e) {
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$api.delete(
            "/admin/vacancy/departament/" + id + "/delete/"
          )
          if (res && res.status === 204) {
            this.$message.success("Новости Успешно удалено")
          }
        } catch (e) {
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    },
    async createFunc() {
      this.$router.push({
        name: "vacDepart-create"
      })
    }
  }
}
</script>
